import {createAction, props} from '@ngrx/store';
import {MappedHttpErrorResponse} from '../../interfaces/mapped-http-error-response.interface';
import {Mandant} from '../../interfaces/mandant.interface';
import {TableSettings} from '../../interfaces/table-settings.interface';
import {FilterDTO, PageableDTO} from '../../openapi/mandanten-verwaltung-openapi';
import {LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';


export class TableActions {

  public static startReadingMandanten = createAction(
    '[Table] Start reading mandanteninfo.',
    props<{ filter: FilterDTO, pageable: PageableDTO }>(),
  );

  public static succeededReadingMandanten = createAction(
    '[Table] Succeeded reading mandanteninfo.',
    props<{ mandanten: Mandant[], length: number }>(),
  );

  public static failedReadingMandanten = createAction(
    '[Table] Failed reading mandanteninfo.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static changeTableSettings = createAction(
    '[Table] Changed table settings.',
    props<{ tableSettings: TableSettings }>(),
  );

  public static changeSearchtext = createAction(
    '[Table] Changed table searchtext.',
    props<{ searchtext: string }>(),
  );

  public static changePage = createAction(
    '[Table] Changed table pagination.',
    props<{ pageEvent: PageEvent }>(),
  );

  public static changePaginationLength = createAction(
    '[Table] Changed table pagination length.',
    props<{ length: number }>(),
  );

}
