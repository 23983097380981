import {Component, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {BehaviorSubject, filter, map, Subject, take, takeUntil} from 'rxjs';
import {Config, ConfigService} from './modules/config/service/config.service';
import {IconService} from './modules/icon/icon.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
  implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  readonly _authenticated$ = new BehaviorSubject<boolean>(false);

  constructor(
    private iconService: IconService,
    private titleService: Title,
    private configService: ConfigService,
    private oidcSecurityService: OidcSecurityService,
  ) {
    // info: update title
    this.configService.loaded$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(value => {
        this.updateTitle(value);
      });
    this.oidcSecurityService.isAuthenticated$
      .pipe(
        takeUntil(this.unsubscribe$),
        map(value => value?.isAuthenticated),
        filter(value => value),
      )
      .subscribe(value => this._authenticated$.next(value));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get authenticated$() {
    return this._authenticated$.pipe(
      takeUntil(this.unsubscribe$),
    );
  }

  doLogout(): void {
    this.oidcSecurityService.logoff().pipe(take(1)).subscribe();
  }

  private updateTitle(
    config: Config,
  ): void {
    const environment = config?.environment;
    if (!environment || environment === 'production' || environment.length === 0) {
      return;
    }
    const title = this.titleService.getTitle();
    const newTitle = `${title} --${environment.toUpperCase()}--`;

    this.titleService.setTitle(newTitle);
  }
}
