/**
 * Mandanten Verwaltung
 * Ein API zum Importieren von Mandanten in ADNOVA+
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum StatusDTO {
    Aktiviert = 'Aktiviert',
    NichtAktiviert = 'NichtAktiviert'
};

