import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TableActions} from '../actions/table.actions';
import {catchError, map, of, switchMap} from 'rxjs';
import {VerwaltungService} from '../../openapi/mandanten-verwaltung-openapi';
import {NGXLogger} from 'ngx-logger';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {tap} from 'rxjs/operators';
import {mapErrorResponse} from '../operator-services/mapErrorResponse';
import {Mandant} from '../../interfaces/mandant.interface';


@Injectable()
export class TableEffects {

  constructor(
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private actions$: Actions,
    private verwaltungService: VerwaltungService,
  ) {
  }

  readonly startReadingMandanteninfo$ = createEffect(
    () => this.actions$.pipe(
      ofType(TableActions.startReadingMandanten),
      switchMap(action => {
        return this.verwaltungService.countMandanteninfo(action.filter).pipe(
          switchMap(length => this.verwaltungService.readMandanteninfo(
            action.filter,
            action.pageable,
          ).pipe(
            map(mandanten => mandanten.map(mandantInfo => {
              const mandant: Mandant = {
                ...mandantInfo,
                activating: false,
              };
              return mandant;
            })),
            map(mandanten => TableActions.succeededReadingMandanten({mandanten, length})),
            catchError(error => of(error).pipe(
              mapErrorResponse(error),
              map(error => TableActions.failedReadingMandanten({error})),
            )),
          )),
        );
      }),
    ),
  );

  readonly succeededReadingMandanteninfo$ = createEffect(
    () => this.actions$.pipe(
      ofType(TableActions.succeededReadingMandanten),
      tap(action => {
        const mandanten = action.mandanten;
        this.logger.debug(`Loaded mandanten, count=${mandanten.length}`);
        this.logger.trace(
          'Loaded mandanten',
          mandanten,
        );
      }),
    ), {dispatch: false}
  );

  readonly failedReadingMandanteninfo$ = createEffect(
    () => this.actions$.pipe(
      ofType(TableActions.failedReadingMandanten),
      tap(action => {
        const error = action.error;
        this.logger.error('Error loading mandanten', error);
        this.snackbar.open(
          'Mandanten konnten nicht geladen werden, bitte versuchen Sie es später erneut.',
          undefined,
          {duration: 4000}
        );
      }),
    ), {dispatch: false}
  );

}
