import {createAction, props} from '@ngrx/store';
import {MappedHttpErrorResponse} from '../../interfaces/mapped-http-error-response.interface';
import {Mandant} from '../../interfaces/mandant.interface';


export class MandantActions {

  public static createUnternehmen = createAction(
    '[Mandant] Send createUnternehmen.',
    props<{ mandant: Mandant }>(),
  );

  public static createUnternehmenSuccess = createAction(
    '[Mandant] CreateUnternehmen succeeded.',
    props<{ mandant: Mandant }>(),
  );

  public static createUnternehmenFailure = createAction(
    '[Mandant] CreateUnternehmen failed.',
    props<{ error: MappedHttpErrorResponse, mandant: Mandant }>(),
  );

  public static createBenutzer = createAction(
    '[Create Benutzer] Send createBenutzer.',
    props<{
      benutzerId: string,
      benutzerSecret: string,
    }>(),
  );

  public static createBenutzerSuccess = createAction(
    '[Create Benutzer] CreateBenutzer succeeded.',
    props<{ benutzerId: string }>(),
  );

  public static createBenutzerFailure = createAction(
    '[Create Benutzer] CreateBenutzer failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static createBenutzerData = createAction(
    '[Create Benutzer] Set CreateBenutzerData into store.',
    props<{
      benutzerId: string,
      benutzerSecret: string,
    }>(),
  );

}
