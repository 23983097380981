export interface Info {
  name: string;
  version: string;
  commit: string;
  commitShort: string;
  branch: string;
  tag: string;
  refName: string;
  buildNumber: string;
  buildTime: string;
}

export const environment = {
  production: true,
  info: Object.entries(require('./info.json'))
    .filter(entry => entry[0] !== '_comment')
    .reduce((obj, entry) => {
      // @ts-ignore
      obj[entry[0]] = entry[1];
      return obj;
    }, {}) as Info,
};
