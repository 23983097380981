import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AutoLoginAllRoutesGuard} from 'angular-auth-oidc-client';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/table',
    pathMatch: 'full'
  },
  {
    path: 'table',
    loadChildren: () => import('./modules/mandanten-table/mandanten-table.module').then(m => m.MandantenTableModule),
    canActivate: [AutoLoginAllRoutesGuard],
  },
  {
    path: 'activate-benutzer',
    loadChildren: () => import('./modules/activate-benutzer/activate-benutzer.module').then(m => m.ActivateBenutzerModule),
  },
  {
    path: '**',
    redirectTo: '',
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
