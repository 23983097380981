import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, of, switchMap} from 'rxjs';
import {VerwaltungService} from '../../openapi/mandanten-verwaltung-openapi';
import {NGXLogger} from 'ngx-logger';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {MandantActions} from '../actions/mandant.actions';
import {tap} from 'rxjs/operators';
import {mapErrorResponse} from '../operator-services/mapErrorResponse';


@Injectable()
export class MandantEffects {

  constructor(
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private actions$: Actions,
    private verwaltungService: VerwaltungService,
  ) {
  }

  // INFO: Actions zum Erstellen eines Nutzers aus der Tabelle heraus.

  readonly createUnternehmen$ = createEffect(
    () => this.actions$.pipe(
      ofType(MandantActions.createUnternehmen),
      switchMap(action => {
        const mandant = action.mandant;
        return this.verwaltungService.createUnternehmen(mandant.benutzerId!).pipe(
          map(() => MandantActions.createUnternehmenSuccess({mandant})),
          catchError(error => of(error).pipe(
            mapErrorResponse(error),
            map(error => MandantActions.createUnternehmenFailure({error, mandant})),
          )),
        );
      }),
    ),
  );

  readonly createUnternehmenSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MandantActions.createUnternehmenSuccess),
      tap(action => {
        const mandant = action.mandant;
        this.logger.debug(`activated mandant, count=${mandant}`);
        this.logger.trace(
          'Activated mandant',
          mandant,
        );
      }),
    ), {dispatch: false}
  );


  readonly createUnternehmenFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MandantActions.createUnternehmenFailure),
      tap(action => {
        const error = action.error;
        const mandant = action.mandant;
        this.logger.error('error activating mandant', error, mandant);
        this.snackbar.open(
          'Mandant konnte nicht aktiviert werden, bitte versuche es später erneut.',
          undefined,
          {duration: 4000}
        );
      }),
    ), {dispatch: false}
  );


  // INFO: Actions zum Aktivieren eines Nutzers per E-Mail.

  readonly createBenutzer$ = createEffect(
    () => this.actions$.pipe(
      ofType(MandantActions.createBenutzer),
      switchMap(({
                   benutzerId,
                   benutzerSecret,
                 }) => {
        return this.verwaltungService.createBenutzer(benutzerId, benutzerSecret).pipe(
          map(() => MandantActions.createBenutzerSuccess({benutzerId})),
          catchError(error => of(error).pipe(
            mapErrorResponse(error),
            map(error => MandantActions.createBenutzerFailure({error})),
          )),
        );
      }),
    ),
  );

  readonly createBenutzerSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MandantActions.createBenutzerSuccess),
      tap(action => {
        this.logger.info(
          'create benutzer',
          'benutzerId:',
          action.benutzerId,
        );
      }),
    ), {dispatch: false}
  );

  readonly createBenutzerFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MandantActions.createBenutzerFailure),
      tap(action => {
        const error = action.error;
        this.logger.error('error creating benutzer', error);
      }),
    ), {dispatch: false}
  );

}
