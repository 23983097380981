<mat-toolbar [hidden]="!(authenticated$ | async)">
  <span class="standalone-toolbar-spacer"></span>
  <button mat-icon-button tabindex="-1" (click)="doLogout()">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>

<div class="content-outlet">
  <router-outlet></router-outlet>
</div>
