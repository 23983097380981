import {createReducer, on} from '@ngrx/store';
import {TableState} from '../../interfaces/table-state.interface';
import {TableActions} from '../actions/table.actions';
import {MandantActions} from '../actions/mandant.actions';
import {StatusDTO} from '../../openapi/mandanten-verwaltung-openapi';
import {ifDifferent} from '../helper';


export const initialTableState: TableState = {
  pagination: {
    pageIndex: 0,
    length: 0,
    pageSize: 15,
  },
  searchtext: '',
  data: {
    loading: false,
    mandanten: [],
  },
};

export const tableReducer = createReducer(

  initialTableState,

  on(
    TableActions.startReadingMandanten,
    (state) => ({
      ...state,
      data: {
        loading: true,
        error: undefined,
        mandanten: [],
      },
    })
  ),

  on(
    TableActions.succeededReadingMandanten,
    (state, action) => ({
      ...state,
      pagination: ifDifferent('length', state.pagination, action.length),
      data: {
        loading: false,
        mandanten: action.mandanten,
      },
    })
  ),

  on(
    TableActions.failedReadingMandanten,
    (state, action) => ({
      ...state,
      data: {
        loading: false,
        error: action.error.error,
        mandanten: [],
      },
    })
  ),

  on(
    TableActions.changeTableSettings,
    (state, action) => ({
      ...state,
      tableSettings: action.tableSettings,
    })
  ),

  on(
    TableActions.changeSearchtext,
    (state, action) => ({
      ...state,
      searchtext: action.searchtext,
    })
  ),

  on(
    TableActions.changePage,
    (state, action) => ({
      ...state,
      pagination: ifDifferent('pageIndex', state.pagination, action.pageEvent.pageIndex),
    })
  ),

  on(
    MandantActions.createUnternehmen,
    (state, action) => ({
      ...state,
      data: {
        ...state.data,
        mandanten: state.data.mandanten.map(mandant => {
          if (mandant.benutzerId === action.mandant.benutzerId) {
            return {
              ...mandant,
              activating: true,
            };
          }
          return mandant;
        }),
      },
    })
  ),

  on(
    MandantActions.createUnternehmenSuccess,
    (state, action) => ({
      ...state,
      data: {
        ...state.data,
        mandanten: state.data.mandanten.map(mandant => {
          if (mandant.benutzerId === action.mandant.benutzerId) {
            return {
              ...mandant,
              activating: false,
              status: StatusDTO.Aktiviert,
            };
          }
          return mandant;
        }),
      },
    })
  ),

  on(
    MandantActions.createUnternehmenFailure,
    (state, action) => ({
      ...state,
      data: {
        ...state.data,
        mandanten: state.data.mandanten.map(mandant => {
          if (mandant.benutzerId === action.mandant.benutzerId) {
            return {
              ...mandant,
              activating: false,
            };
          }
          return mandant;
        }),
      },
    })
  ),

);
