import {MandantState} from '../../interfaces/mandant-state.interface';
import {createReducer, on} from '@ngrx/store';
import {MandantActions} from '../actions/mandant.actions';


export const initialMandantState: MandantState = {};

export const mandantReducer = createReducer(
  initialMandantState,

  on(
    MandantActions.createBenutzerSuccess,
    (state) => ({
      ...state,
      createBenutzerSuccess: true,
    })
  ),

  on(
    MandantActions.createBenutzerFailure,
    (state) => ({
      ...state,
      createBenutzerSuccess: false,
    })
  ),

  on(
    MandantActions.createBenutzerData,
    (state, action) => ({
      ...state,
      benutzerId: action.benutzerId,
      benutzerSecret: action.benutzerSecret,
    })
  ),

);
