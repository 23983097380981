/**
 * Mandanten Verwaltung
 * Ein API zum Importieren von Mandanten in ADNOVA+
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PageableDTO { 
    offset?: number;
    limit?: number;
    sorting?: Array<PageableDTOSortingEnum>;
}
export enum PageableDTOSortingEnum {
    Vornameasc = 'VORNAMEASC',
    Vornamedesc = 'VORNAMEDESC',
    Nachnameasc = 'NACHNAMEASC',
    Nachnamedesc = 'NACHNAMEDESC',
    Emailasc = 'EMAILASC',
    Emaildesc = 'EMAILDESC',
    Strasseasc = 'STRASSEASC',
    Strassedesc = 'STRASSEDESC',
    Postfachasc = 'POSTFACHASC',
    Postfachdesc = 'POSTFACHDESC',
    Plzasc = 'PLZASC',
    Plzdesc = 'PLZDESC',
    Ortasc = 'ORTASC',
    Ortdesc = 'ORTDESC'
};



