import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';


interface IconSpec {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class IconService {
  iconList: IconSpec[] = [
    {
      name: 'gear',
    },
    {
      name: 'search',
    },
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.iconList.forEach(icon => {
      iconRegistry.addSvgIcon(
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.name}.svg`)
      );
    });
  }
}
