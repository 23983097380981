import {AppState} from '../interfaces/app-state.interface';
import {ActionReducerMap} from '@ngrx/store';
import {initialTableState, tableReducer} from './reducers/table.reducer';
import {initialMandantState, mandantReducer} from './reducers/mandant.reducer';


export const initialState: AppState = {
  table: initialTableState,
  mandant: initialMandantState,
};

export const reducers: ActionReducerMap<AppState> = {
  table: tableReducer,
  mandant: mandantReducer,
};
