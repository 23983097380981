export function ifDifferent<T>(propertyName: keyof T, currentObject: T, newValue: any): T {
  if (currentObject[propertyName] === newValue) {
    return currentObject;
  }

  return {
    ...currentObject,
    [propertyName]: newValue,
  };
}
