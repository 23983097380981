import {map, OperatorFunction} from 'rxjs';
import {MappedHttpErrorResponse} from '../../interfaces/mapped-http-error-response.interface';
import {HttpErrorResponse} from '@angular/common/http';


export function mapErrorResponse<T>(
  predicate: (value: T) => {
    error: any,
  }
): OperatorFunction<T, MappedHttpErrorResponse> {

  return source$ => source$.pipe(
    map(data => {
      const error = data as unknown as HttpErrorResponse;
      const mappedErrorResponse: MappedHttpErrorResponse = JSON.parse(JSON.stringify(error));
      return mappedErrorResponse;
    }),
  );

}
